import $ from "jquery"

document.addEventListener("turbolinks:load", function() {
    const slideUp = document.body.dataset.alertSlideUp
    if (slideUp === "true") {
        $(".flash-alert").slideUp(500, function() {
            console.log("Alert removed")
            $(this).remove();
        });
    }
});
